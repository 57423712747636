import { AccountInfo, AuthenticationResult, Configuration, PublicClientApplication, SsoSilentRequest } from "@azure/msal-browser";
import { navigateToUrl } from "single-spa";
import config from "./config";
import { auth$, errorState, loggingInState, storageIdTokenKey, unauthenticatedState } from "./oc-auth";

const LOGIN_ROUTE = "/";
const arrScopes = config.msal.scopes && config.msal.scopes.split(",");
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const msalConfig: Configuration = {
    auth: {
        clientId: config.msal.clientId,
        authority: `${config.msal.authority}/${config.msal.tenantId}`,
        postLogoutRedirectUri: config.msal.redirectUri,
        redirectUri: config.msal.redirectUri,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: config.msal.cacheLocation,
        storeAuthStateInCookie: false,
    },
    system: {},
}

const loginTokenRequest = {
    scopes: [...arrScopes],
}

const silentProfileRequest = {
    scopes: [`${config.msal.clientId}/.default`],
    account: null,
    forceRefresh: false,
};

const graphRequest = {
    scopes: [...arrScopes],
    account: null,
    forceRefresh: false,
}

const msalObj: PublicClientApplication = new PublicClientApplication (
    msalConfig
);

const signIn = async () => {  
    msalObj.handleRedirectPromise()
            .then(await doSignIn)
            .catch(e => console.log(e));
};

const doSignIn = async () => {  
    try {
        auth$.next(loggingInState);
        const loggedInAccounts: AccountInfo[] = msalObj && msalObj.getAllAccounts();
        let response: AuthenticationResult;
        const userName = localStorage.getItem("userName");
        if (loggedInAccounts.length) {
            const ssoSilentReq: SsoSilentRequest = { ...loginTokenRequest};
            ssoSilentReq.loginHint = loggedInAccounts[0].homeAccountId;
            console.log(ssoSilentReq.loginHint);
            localStorage.setItem("userName", ssoSilentReq.loginHint);
            response = await msalObj.ssoSilent(ssoSilentReq);
        } else if (userName) {
            if (!userName) {
                localStorage.setItem("userName", userName);
            }
            const silentProfile = {
                scopes: [...arrScopes],
                loginHint: userName,
            };
            try {
                const acqureTokenObj = await msalObj.ssoSilent(silentProfile);
                return acqureTokenObj;
            } catch (e) {
                response = await msalObj.loginPopup(loginTokenRequest);
                // throw e;
            }
        } else {
            response = await msalObj.loginPopup(loginTokenRequest);
        }
        // at sign in
        await refreshAccessToken();
    } catch (error)  {
        auth$.next(errorState(error.message));
    }
};

const setTokens = (
    ocAccessTokenResult: AuthenticationResult,
    graphAccessTokenResult: AuthenticationResult
) => {
    localStorage.setItem(storageIdTokenKey, ocAccessTokenResult?.accessToken);
    localStorage.setItem("userName", ocAccessTokenResult?.account?.username);
    auth$.next ({
        idtoken: ocAccessTokenResult.idToken,
        accessToken: ocAccessTokenResult?.accessToken,
        graphAccessToken: graphAccessTokenResult?.accessToken,
        name: ocAccessTokenResult?.account.name,
        username: ocAccessTokenResult?.account?.username,
        error: "",
        pending: false,
        isAuthenticated: true,
    });
};

const refreshAccessToken = async () => {
    msalObj.handleRedirectPromise()
            .then(await refreshAccessTokenAsync)
            .catch(e => console.log(e));
};

const refreshAccessTokenAsync = async () => {
    try {
        const ocAccessTokenResponse = await fetchAccessToken(silentProfileRequest);
        const graphAccessTokenResponse = await fetchAccessToken(graphRequest);
        setTokens(ocAccessTokenResponse, graphAccessTokenResponse);
        return auth$.value;
    } catch (e: any) {
        console.log(e.toString());
        if (e.toString().includes('interaction_required')) {
            console.log('doing signIn');
            await signIn();
            return auth$.value;
        } else {
            auth$.next(errorState(e.message));
            return auth$.value;
        }
    }
};

const fetchAccessToken = async (requestPayload) => {
    const loggedInAccounts: AccountInfo[] = msalObj && msalObj.getAllAccounts();
    const userName = localStorage.getItem("userName");
    console.log(userName);
    if (loggedInAccounts.length) {
        const silentProfile = {
            ...requestPayload,
            account: loggedInAccounts[0],
        };
        try {
            const acqureTokenObj = await msalObj.acquireTokenSilent(silentProfile);
            return acqureTokenObj;
        } catch (e) {
            throw e;
        }
    } else if (userName) {
        const silentProfile = {
            ...requestPayload,
            account: userName,
        };
        try {
            const acqureTokenObj = await msalObj.ssoSilent(silentProfile);
            return acqureTokenObj;
        } catch (e) {
            console.log(e);
            throw e;
        }
    } else if (userName === null) {
        await doSignIn();
    } else {
        const error = new Error ("No accounts logged in.");
        throw error;
    }
};

const signOut = () => {
    localStorage.removeItem(storageIdTokenKey);
    auth$.next(unauthenticatedState);
    navigateToUrl(LOGIN_ROUTE);
    msalObj.logoutRedirect()
}

export default {
    signIn,
    signOut,
    refreshAccessToken,
    msalObj,
};

