import { BehaviorSubject } from "rxjs";
import { AuthState } from "./interface";
import msalService from "./msal.service";

// Anything exported from this file is importable by other in-browser modules.
export function publicApiFunction() {}

export const storageIdTokenKey = "adal.idtoken";
export const storageUserNameKey = "oc.username";
export const storageAccesssTokenKey = "oc.accesstoken";


export const unauthenticatedState: AuthState = {
    idtoken: null,
    accessToken: null,
    graphAccessToken: null,
    name: "",
    username: "",
    error: "",
    pending: false,
    isAuthenticated: false,
};

export const loggingInState: AuthState = {
    idtoken: null,
    accessToken: null,
    graphAccessToken: null,
    name: "",
    username: "",
    error: "",
    pending: true,
    isAuthenticated: false,
};

export function errorState(error: string): AuthState {
    return {
        idtoken: null,
        accessToken: null,
        graphAccessToken: null,
        name: "",
        username: "",
        error: error,
        pending: false,
        isAuthenticated: false
    };
}

export const auth$ = new BehaviorSubject(unauthenticatedState);

export let refreshPromise = null;

export const refreshAccessToken = (): Promise<AuthState> => {
    if (refreshPromise) {
        return refreshPromise;
    } else {
        refreshPromise = msalService.refreshAccessToken();
        refreshPromise.then((res) => {
            refreshPromise = null;
            return res;
        });
        return refreshPromise;
    }
};

export async function login() {
    if (!auth$.value.pending) {
        return await msalService.signIn();
    }
}

export function logout() {
    msalService.signOut();
}

export function isAuthenticated() {
    return auth$.value.isAuthenticated;
}

refreshAccessToken();